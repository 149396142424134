function Hero() {
	const image = "img/hero.jpg";
	const heroTitle = "img/hero-title.png";
	return (
		<div className="hero-image">
			<img className="w-full" src={image} alt="hero" />
			<div className=" absolute top-24 left-4 md:left-20 ">
				<img className="w-20 md:w-28" src={heroTitle} alt="hero" />
			</div>
		</div>
	);
}

export default Hero;
