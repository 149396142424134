function RoadMap() {
	return (
		<div id="roadmap" className="roadmapflex justify-center bg-black">
			{/* <img className="w-full  lg:h-full" src={Four} alt="hero" /> */}
			<section className="text-gray-600 body-font">
				<h2 className="text-3xl text-[#FFF36A] font-['italic'] uppercase flex justify-center items-center md:text-7xl ">
					Road Map
				</h2>
				<div className="container px-5 py-24 mx-auto flex flex-wrap">
					<div className="flex relative pb-12 md:pb-20 sm:items-center md:w-2/3 mx-auto">
						<div className="h-full w-10 absolute inset-0 flex items-center justify-center">
							<div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
						</div>
						<div className="flex-shrink-0 w-10 h-10 md:-top-[127px] rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
							{/* hh */}
						</div>
						<div className="flex-grow pl-4">
							<h2 className="font-bold title-font text-lg  mb-1 tracking-wider text-[#FFF36A]">
								Fase 1: Apertura Legión Intergaláctica
							</h2>
							<p className="leading-relaxed text-base text-[#FFF36A]">
								[Diciembre 16 de 2021 - enero 21 de 2022]
							</p>
							<p className="leading-relaxed text-white">
								Dic 16: Evento Inaugural Apertura del portal dimensional llegada
								de Maltor a la tierra y el club de la legión intergalácticas
							</p>
							<p className="leading-relaxed text-white">
								Dic 21: Apertura a la lista de espera de compra automática
							</p>
							<p className="leading-relaxed text-white">
								Dic 27: Finalización del concurso internacional que Entrega
								cinco NFT
							</p>
							<p className="leading-relaxed text-white">
								Ene 12: Comunicado oficial de prensa (actualización general)
							</p>
							<p className="leading-relaxed text-white">
								Ene 21: Venta pública de los NFT con transmisión en vivo
							</p>
							<p className="leading-relaxed text-white">
								Ene 21: Celebración y entrega pública de todos los premios
							</p>
						</div>
					</div>
					<div className="flex relative pb-12  md:pb-20 sm:items-center md:w-2/3 mx-auto">
						<div className="h-full w-10 absolute inset-0 flex items-center justify-center">
							<div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
						</div>
						<div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
							{/* hhh */}
						</div>
						<div className="flex-grow pl-4">
							<h2 className="font-bold title-font text-lg  mb-1 tracking-wider text-[#FFF36A]">
								Fase 2: Viaje Intergaláctico
							</h2>
							<p className="leading-relaxed text-base text-[#FFF36A]">
								[Enero 22 - abril 30 de 2022]
							</p>
							<p className="leading-relaxed text-white">
								Durante esta fase se busca dar a conocer los NTF, así como
								generar las estrategias de marketing adecuadas para expandir la
								comunidad. En esta fase se harán:
							</p>
							<ul className="text-white pl-8">
								<li className="leading-relaxed text-white">
									1.Comunicados oficiales de prensa dando a conocer los
									ganadores y haciendo pública la noticia.
								</li>
								<li className="leading-relaxed text-white">
									2.Se crearán campañas publicitarias.
								</li>
								<li className="leading-relaxed text-white">
									3.Habrán Influencers y Youtubers que hablen de los NFT,
									logrando mayor posicionamiento.
								</li>
								<li className="leading-relaxed text-white">
									4.Se usarán las mejores estrategias de marketing.
								</li>
								<li className="leading-relaxed text-white">
									5.Diseño y creación de la segunda colección de NFT.
								</li>
							</ul>
							<p className="leading-relaxed text-white">
								Así mismo, durante la Fase 2, se dará inicio al desarrollo del
								NFT Mining y el NFT Gaming.
							</p>
						</div>
					</div>
					<div className="flex relative pb-12  md:pb-20 sm:items-center md:w-2/3 mx-auto">
						<div className="h-full w-10 absolute inset-0 flex items-center justify-center">
							<div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
						</div>
						<div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10"></div>
						<div className="flex-grow pl-4">
							<h2 className="font-bold title-font text-lg  mb-1 tracking-wider text-[#FFF36A]">
								Fase 3: Expansión Intergaláctica
							</h2>
							<p className="leading-relaxed text-base text-[#FFF36A]">
								[Mayo 1 - julio 30 de 2022]
							</p>
							<p className="leading-relaxed text-white">
								Esta fase corresponde a la consolidación, la integración de la
								legión intergaláctica y así mismo al lanzamiento de la segunda
								colección de NFT. En esta fase se harán:
							</p>
							<ul className="text-white pl-8">
								<li className="leading-relaxed text-white">
									1.Preparación del Festival Intergaláctico.
								</li>
								<li className="leading-relaxed text-white">
									2.Confirmación y entrega de entradas VIP.
								</li>
								<li className="leading-relaxed text-white">
									3.Venta pública de la segunda colección de NFT.
								</li>
								<li className="leading-relaxed text-white">
									4.Lanzamiento de NFT Mining.
								</li>
								<li className="leading-relaxed text-white">
									5.Lanzamiento de NFT Gaming.
								</li>
								<li className="leading-relaxed text-white">
									6.Realización del Festival Intergaláctico.
								</li>
							</ul>
						</div>
					</div>
					<div className="flex relative pb-12  md:pb-20 sm:items-center md:w-2/3 mx-auto">
						<div className="h-full w-10 absolute inset-0 flex items-center justify-center">
							<div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
						</div>
						<div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10"></div>
						<div className="flex-grow pl-4">
							<h2 className="font-bold title-font text-lg  mb-1 tracking-wider text-[#FFF36A]">
								Fase 4: Reload Intergaláctico
							</h2>
							<p className="leading-relaxed text-base text-[#FFF36A]">
								[Del 1 agosto de 2022 en adelante]
							</p>
							<p className="leading-relaxed text-white">
								egún la experiencia de las fases anteriores y la respuesta de
								los usuarios, se crearán las siguientes fases del Club de la
								Legión Intergaláctica.
							</p>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default RoadMap;
