function Footer() {
	const logo = "img/logo.png";
	const footer = "img/footer.png";
	return (
		<div className="footer bg-black w-full pb-8 pt-8">
			<div className="w-full flex justify-center ">
				<img className="w-28 " src={footer} alt="hero" />
				<img className="w-40 pl-3" src={logo} alt="hero" />
			</div>
		</div>
	);
}

export default Footer;
