import "./App.css";
import Club from "./Club";
import Faq from "./Faq.js";
import Festival from "./Festival.js";
import Footer from "./Footer.js";
import Hero from "./Hero.js";
import Navbar from "./Navbar.js";
import RoadMap from "./RoadMap.js";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Fortmatic from "fortmatic";
import * as customAlert from "js-alert";

// global variables
window.saleAddress = "0xdCB03fF089A7B5c5AFf414263C833f4Faa039248";
window.usdcAddress = "0x2791bca1f2de4661ed88a30c99a7a9449aa84174";
window.alertShow = false;

function App() {



	return (
		<div id="root" className="w-full" onLoad={window.connectWeb3}>
			<Navbar />
			<Hero />
			<Club />
			<Festival />
			<RoadMap />
			<Faq />
			<Footer />
		</div>
	);
}

window.connectWeb3 = async function () {

	window.errorImage = "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0icmVkIiBkPSJNMTEuMDAxIDEwaDJ2NWgtMnpNMTEgMTZoMnYyaC0yeiIvPjxwYXRoIGZpbGw9InJlZCIgZD0iTTEzLjc2OCA0LjJDMTMuNDIgMy41NDUgMTIuNzQyIDMuMTM4IDEyIDMuMTM4cy0xLjQyLjQwNy0xLjc2OCAxLjA2M0wyLjg5NCAxOC4wNjRhMS45ODYgMS45ODYgMCAwIDAgLjA1NCAxLjk2OEExLjk4NCAxLjk4NCAwIDAgMCA0LjY2MSAyMWgxNC42NzhjLjcwOCAwIDEuMzQ5LS4zNjIgMS43MTQtLjk2OGExLjk4OSAxLjk4OSAwIDAgMCAuMDU0LTEuOTY4TDEzLjc2OCA0LjJ6TTQuNjYxIDE5IDEyIDUuMTM3IDE5LjM0NCAxOUg0LjY2MXoiLz48L3N2Zz4=";

	const providerOptions = {
		walletconnect: {
			package: WalletConnectProvider,
			options: {
				infuraId: "2298daa8fd80468eb405781efd636b41",
				rpc: {
					137: "https://polygon-rpc.com/",
				},
			},
		},

		fortmatic: {
			package: Fortmatic,
			options: {
				key: "pk_live_2DAF26D1167A6E9E",
			},
		},
	};

	window.web3Modal = new Web3Modal({
		chainid: "137",
		cacheProvider: true, // optional
		providerOptions, // required
		disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
	});

	window.provider = await window.web3Modal.connect();
	// this.headKey += 2;
	// this.buyKey += 2;
	window.$web3 = new Web3(window.provider);

	if ((await window.$web3.eth.net.getId()) !== 137) {
		window.provider = null;
		window.$web3 = null;
		if (!window.alertShow) {
			window.alertShow = true;
			customAlert.alert("Red incorrecta, por favor cambiar a la red de principal de polygon", "Error", window.errorImage).then(function () {
				window.alertShow = false;
			});
		}
		return;
	}

	window.provider.on("connect", (info) => {
		// this.headKey += 2;
		// this.buyKey += 2;
		// console.log(info);
	});

	// Subscribe to provider disconnection
	window.provider.on("disconnect", (error) => {
		window.provider = null;
		window.$web3 = null;
		// this.headKey += 2;
		// this.buyKey += 2;
		// console.log(error);
	});


}
export default App;
