function Festival() {
	return (
		<div id="festival" className="Festival bg-black text-white py-20">
			<div className="w-full">
				<h1 className="w-full flex justify-center text-3xl md:text-7xl uppercase text-[#FFF33D] font-['italic'] font-bold">
					Bienvenidos al Club
				</h1>
				<h2 className="w-full flex justify-center text-xl md:text-7xl  uppercase text-[#A69F6D]">
					de la Legión Intergaláctica
				</h2>
			</div>
			{/* description */}
			<section class="text-gray-600 body-font overflow-hidden">
				<div class="container px-5 py-14 mx-auto">
					<div class="flex flex-wrap -m-12">
						<div class="p-6 md:w-1/2 mx-10 m-auto md:mx-0 flex flex-col items-start">
							<p class="leading-relaxed md:ml-3  md:mb-8 text-white">
								Un grupo de 10 mil alienígenas de 9 especies diferentes, quienes
								en el año 2099 se unieron para abrir un portal dimensional y así
								viajar en el tiempo al año 2021.
							</p>
						</div>
						<div class="md:p-12 md:w-1/2 mx-10 m-auto md:mx-0 flex flex-col items-start">
							<p class="leading-relaxed md:mb-8 text-white">
								Su misión, capturar y congelar a Maltor, quien viajó primero
								para promover en la raza humana un estilo de vida lleno de
								ambición, lujuria y excesos. Maltor, quien por mucho tiempo fue
								el líder de las legiones, fue sustituido por Bitronious y
								capturado por los 9 Líderes Galácticos y sus legiones,
								inmortalizados cada uno con un NFT.
							</p>
						</div>
					</div>
				</div>
			</section>
			{/* two */}
			<h2 className="w-full flex justify-center text-xl md:text-7xl  uppercase text-[#A69F6D]">
				CONFEDERACIÓN INTERGALÁCTICO
			</h2>
			<p className="container px-5 py-10 mx-auto leading-relaxed  text-white">
				uienes sean los dueños de los 9 líderes galácticos recibirán 1 ETH, el
				dueño del NTF Morfus ganará 3 BTC, todos ellos recibirán la invitación
				VIP para el Festival Intergaláctico.
			</p>
			<section class="text-gray-600 body-font overflow-hidden">
				<div class="container px-5 py-6 mx-auto">
					<div class="flex flex-wrap -m-12">
						<div class="p-12 md:w-1/2 flex flex-col items-start">
							<h1 class="w-full flex justify-center text-xl md:text-7xl  uppercase text-[#A69F6D]">
								FESTIVAL INTERGALÁCTICO
							</h1>
						</div>
						<div class="md:p-12 mx-10 m-auto md:mx-0 md:w-1/2 flex flex-col items-start">
							<p class="leading-relaxed md:mb-8 text-white">
								Su misión, capturar y congelar a Maltor, quien viajó primero
								para promover en la raza humana un estilo de vida lleno de
								ambición, lujuria y excesos. Maltor, quien por mucho tiempo fue
								el líder de las legiones, fue sustituido por Bitronious y
								capturado por los 9 Líderes Galácticos y sus legiones,
								inmortalizados cada uno con un NFT.
							</p>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Festival;
