import React from "react";

function Faq() {
	// const Five = "img/5.jpg";
	return (
		<div id="faq" className="bg-black h-full pb-3">
			<div class="w-full accordion  md:w-['80rem'] m-auto">
				<h2 className="text-3xl text-[#FFF36A] font-['italic'] uppercase flex justify-center items-center md:text-7xl ">
					FAQ
				</h2>
				<Accordion className="pb-4" defaultPanel="panel-1">
					<AccordionItem
						toggle="panel-1"
						className="bg-[#FFF385] text-black pl-8 text-lg md:text-2xl uppercase font-bold font-['Roboto']">
						¿Qué es el Club de la Legión Intergaláctica?
					</AccordionItem>
					<AccordionPanel className="bg-[#FFF385] " id="panel-1">
						<div className="rounded-b-3xl rounded-t-lg px-8 py-2 bg-[#D8C95D] text-black">
							<p className="mb-4">
								Es un club de Crypto lovers, amantes de los NFTs, quienes
								estamos en diferentes partes del mundo, unidos por una
								comunidad, en donde entendemos que juntos podemos lograr grandes
								cosas que nos beneficie a todos.
							</p>
						</div>
					</AccordionPanel>
					<AccordionItem
						toggle="panel-2"
						className="bg-[#FFF385] text-black pl-8 text-lg md:text-2xl uppercase font-bold font-['Roboto']">
						¿Cuál es el propósito del Club?
					</AccordionItem>
					<AccordionPanel id="panel-2">
						<div className="rounded-b-3xl rounded-t-lg px-8 py-2 bg-[#D8C95D] text-black">
							<p className="mb-4">
								Nuestro propósito es construir una comunidad lo suficientemente
								grande y sólida que nos permita generar beneficios para todos.
							</p>
						</div>
					</AccordionPanel>
					<AccordionItem
						toggle="panel-3"
						className="bg-[#FFF385] text-black pl-8 text-lg md:text-2xl uppercase font-bold font-['Roboto']">
						¿Quiénes conforman el Club de la Legión Intergaláctica?
					</AccordionItem>
					<AccordionPanel id="panel-3">
						<div className="rounded-b-3xl rounded-t-lg px-8 py-2 bg-[#D8C95D] text-black">
							<h1 className="text-xl font-bold">Bitronious - el Benevolent</h1>
							<p className="mb-4">
								Bitronious es el nuevo líder de todas las galaxias, elegido por
								el Club de la Legión Intergaláctica, quien lidera el viaje en el
								tiempo de los 9 Líderes Galácticos y todas las legiones a través
								del portal dimensional con el fin de capturar y congelar a
								Maltor.
							</p>
							<p className="">
								Es grande tanto de estatura como de corazón. Sus actos, aunque a
								veces parecen irracionales o duros, los hace sin ni siquiera una
								pizca de intención maliciosa. Bitronious quiere lo mejor para su
								gente y no guarda rencor a nada y a nadie. Respeta a quienes
								acatan las reglas y, a su vez, la gente lo respeta.
							</p>
							<p className="">
								Aquellos que no acatan las leyes del Club son exiliados y se les
								da la opción de forjar su propio camino, pero ya no pueden
								regresar sin importar los elogios que luego puedan obtener.
								Líder de una gran y poderosa población de razas de toda la
								galaxia. Bitronious no discrimina y no tolera este
								comportamiento de su gente.
							</p>
							<p>El dueño del NTF Bitronious ganará 3 BTC.</p>
							<h1 className="text-xl font-bold">Maltor the Malevolent</h1>
							<p>
								Controlando a su pueblo a través del miedo, el chantaje y el
								engaño. Maltor buscará cualquier motivo para cortar a alguien.
								Míralo de la manera equivocada, haz un comentario grosero,
								respira demasiado fuerte. ¡Estás muerto! Demonios, podría
								igualar toda tu línea de sangre para trabajar en las minas de
								cristal de sangre Zenaro durante las generaciones venideras. Un
								trabajo desagradable con un ego enorme y ansias de derramamiento
								de sangre. Las prioridades de Maltor solo involucran la guerra y
								la dominación.
							</p>
							<p>
								<strong>Personalidad:</strong> ego despiadado, condescendiente,
								manipulador, frágil
							</p>
							<p>
								<strong>Tipo:</strong> Humanoide tipo lagarto con cola y cabeza
								de tipo drogónico
							</p>
							<p>
								<strong>Rasgos:</strong> uerza animal, puede usar la cola como
								un arma poderosa que perfora acero con facilidad, un instinto
								agudo.
							</p>
							<p>
								9 Líderes Galácticos 9 líderes, cada uno de una raza alienígena
								diferente, quien tiene a cargo una legión de 1.111 alienígenas
								de su misma especie. Ellos se unen con el propósito de apoyar la
								captura de Maltor y de sembrar en la raza humana un cambio en la
								forma de pensar y a su vez mostrar al mundo cómo vivir desde la
								consciencia, la unidad y la armonía. Quien reciba cada uno de
								estos líderes obtendrá un Bono de 1 ETH, además de la invitación
								VIP al Festival Intergaláctico. 99 Generales Son los encargados
								de liderar una milicia de 101 alienígenas, todos enfocados en
								capturar y congelar a Maltor, además de apoyar el cambio de la
								conciencia humana hacia una nueva humanidad. Quienes reciban
								alguno de estos generales recibirán una invitación VIP al
								Festival Intergaláctico en Ibiza. Legionarios Son todos los que
								conforman el Club de la Legión Intergaláctica.
							</p>
						</div>
					</AccordionPanel>
					<AccordionItem
						toggle="panel-4"
						className="bg-[#FFF385] text-black pl-8 text-lg md:text-2xl uppercase font-bold font-['Roboto']">
						¿Qué gano al recibir mi NFT?
					</AccordionItem>
					<AccordionPanel className="bg-[#FFF385] " id="panel-4">
						<div className="rounded-b-3xl rounded-t-lg px-8 py-2 bg-[#D8C95D] text-black">
							<p className="mb-4">
								Al recibir tu NFT ganas el ser parte de una comunidad creciente
								y exclusiva que tiene como propósito generar beneficios para
								todos, además, recibirás un NFT con gran potencial de
								valorización. La asignación de los NFT es de forma aleatoria y
								transparente para toda la comunidad ya que nos soportamos en la
								tecnología Blockchain mediante el siguiente contrato
								inteligente: <strong>678FHGJ765Tyhn41wsfFGVBjhf5GH78</strong>
							</p>
							<ul className="text-black pl-8">
								<li className="leading-relaxed ">
									1.Si recibes a Bitronious este NFT viene con una BONO especial
									de 3 NFT e invitación VIP al Festival Intergaláctico.
								</li>
								<li className="leading-relaxed ">
									2.Si recibes alguno de los 9 Líderes Galácticos, cada uno de
									ellos viene con un BONO de 1 ETH e invitación VIP al Festival
									Intergaláctico.
								</li>
								<li className="leading-relaxed ">
									3.Si recibes alguno de los 99 Generales cada uno de ellos
									viene con la invitación VIP al Festival Intergaláctico.
								</li>
								<li className="leading-relaxed ">
									4.Si recibes alguno de los demás legendarios, integrantes de
									la Legión Intergaláctica, recibirás el ticket de acceso al
									Festival Intergaláctico.
								</li>
							</ul>
						</div>
					</AccordionPanel>
					<AccordionItem
						toggle="panel-5"
						className="bg-[#FFF385] text-black pl-8 text-lg md:text-2xl uppercase font-bold font-['Roboto']">
						¿Cuándo se llevará a cabo la venta pública y cómo puedo comprar los
						NFT?
					</AccordionItem>
					<AccordionPanel className="bg-[#FFF385] " id="panel-5">
						<div className="rounded-b-3xl rounded-t-lg px-8 py-2 bg-[#D8C95D] text-black">
							<p className="mb-4">
								La venta pública se llevará a cabo el día 21 de enero a las
								UTC-2 en un evento internacional, el cual será transmitido en
								directo por varias plataformas. Comprar tu NFT es muy fácil ya
								que creamos un Smart Contract que facilita todo el proceso, el
								cual es el siguiente:
								<strong>678FHGJ765Tyhn41wsfFGVBjhf5GH78</strong>
								<p>
									{" "}
									<strong>Necesitas seguir los siguientes pasos:</strong>
								</p>
								<p>Tener una Wallet como Metamask o Trust Wallet </p>
								<p>Tener el saldo equivalente en MATIC o USDC by MATIC.</p>
								<p>
									En la parte superior e inferior encontrarás un cuadro de
									Compra, en donde necesitarás hacer lo siguiente:
								</p>
								<ul className="text-black pl-8">
									<li className="leading-relaxed ">1. Conecta tu wallet</li>
									<li className="leading-relaxed ">
										2. Ingresa cuántos NFTs te gustaría comprar
									</li>
									<li className="leading-relaxed ">
										3. Verás el valor correspondiente, el cual necesitas
										confirmar
									</li>
									<li className="leading-relaxed ">
										4. Y listo!. recibirás de forma automática y aleatoria tu
										NFT.
									</li>
								</ul>
							</p>
						</div>
					</AccordionPanel>

					<AccordionItem
						toggle="panel-6"
						className="bg-[#FFF385] text-black pl-8 text-lg md:text-2xl uppercase font-bold font-['Roboto']">
						¿Cuánto valen los NFT?
					</AccordionItem>
					<AccordionPanel className="bg-[#FFF385] " id="panel-6">
						<div className="rounded-b-3xl rounded-t-lg px-8 py-2 bg-[#D8C95D] text-black">
							<p className="mb-4">
								Cada uno de los NFTs tiene un valor que varía entre 1.200 USD y
								4.500, sin embargo, tenemos una estrategia de valorización por
								lo cual el dia de la venta pública van tener un costo de $ 600
								USD el 21 de enero del año 2022, adicionalmente los usuarios
								interesados pueden obtener un descuento adicional si deciden
								reservarlo y estar en la lista de espera de compra automática el
								cual empezaría el día 21 de Diciembre en $ 300 USD.
							</p>
							<ul className="text-black pl-8">
								<li className="leading-relaxed ">
									1.Ultra rare - $4.500 Bitronius
								</li>
								<li className="leading-relaxed ">
									2.Extremely rare $3.000 Intergalactic 9
								</li>
								<li className="leading-relaxed ">
									3.Highly rare $2.100 99 Generals
								</li>
								<li className="leading-relaxed ">
									4.Very rare $1.600 Outstanding in the Legion
								</li>
								<li className="leading-relaxed ">5.Rare $1.200 Legionnaire</li>
							</ul>
						</div>
					</AccordionPanel>
					<AccordionItem
						toggle="panel-7"
						className="bg-[#FFF385] text-black pl-8 text-lg md:text-2xl uppercase font-bold font-['Roboto']">
						¿Qué es la reserva o lista de espera de compra automática y cómo
						puedo participar?
					</AccordionItem>
					<AccordionPanel className="bg-[#FFF385] " id="panel-7">
						<div className="rounded-b-3xl rounded-t-lg px-8 py-2 bg-[#D8C95D] text-black">
							<p className="mb-4">
								Prevemos que el día de la venta pública puede generarse
								congestión y saturación de red porque muchas personas van a
								querer comprar al mismo tiempo, por esto hemos creado un Smart
								Contract Público:
								<strong>678FHGJ765Tyhn41wsfFGVBjhf5GH78</strong> , el cual te
								permite pagar de forma anticipada y reservar tu NFT.
							</p>
							<p>
								Esto, garantizará que obtengas el tuyo además de comprarlo con
								un descuento especial que te permitirá obtener mayor
								rentabilidad en la valorización del mismo. El Smart Contract
							</p>
						</div>
					</AccordionPanel>
				</Accordion>
			</div>
		</div>
	);
}

/* Logic */

const Context = React.createContext({});

function Accordion({ children, defaultPanel }) {
	const [selected, setSelected] = React.useState(defaultPanel || "");

	const toggleItem = React.useCallback(
		(id) => () => {
			setSelected((prevState) => (prevState !== id ? id : ""));
		},
		[]
	);
	return (
		<Context.Provider value={{ selected, toggleItem }}>
			{children}
		</Context.Provider>
	);
}

//custom hook to consume all accordion values
const useAccordion = () => React.useContext(Context);

const style = {
	item: `w-11/12 mt-8 md:w-7/12 2xl:w-9/12 m-auto block focus:outline-none rounded-3xl p-3`,
	panel: `w-11/12 md:w-7/12 2xl:w-9/12 m-auto overflow-hidden md:overflow-x-hidden transition-height ease duration-300 text-gray-600`,
};

function AccordionItem({ toggle, children, className }) {
	const { selected, toggleItem } = useAccordion();
	return (
		<div
			role="button"
			onClick={toggleItem(toggle)}
			className={`${style.item} ${className}`}>
			{children}
			<span className="float-right">
				{selected === toggle ? <AngleUpIcon /> : <AngleDownIcon />}
			</span>
		</div>
	);
}

function AccordionPanel({ children, id }) {
	const { selected } = useAccordion();
	const ref = React.useRef();
	const inlineStyle =
		selected === id ? { height: ref.current?.scrollHeight } : { height: 0 };

	return (
		<div ref={ref} id={id} className={style.panel} style={inlineStyle}>
			{children}
		</div>
	);
}

const AngleUpIcon = () => (
	<svg
		fill="#333"
		strokeWidth="0"
		viewBox="0 0 320 512"
		xmlns="http://www.w3.org/2000/svg"
		className="mt-1 h-6 w-6">
		<path d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z" />
	</svg>
);

const AngleDownIcon = () => (
	<svg
		stroke="currentColor"
		fill="#333"
		strokeWidth="0"
		viewBox="0 0 320 512"
		xmlns="http://www.w3.org/2000/svg"
		className="mt-1 h-6 w-6">
		<path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
	</svg>
);
export default Faq;
