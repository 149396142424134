import "./App.css";
import React from 'react';
import Modal from 'react-modal';
import alienSaleAbi from "./abi/alienSale.json";
import erc20Abi from "./abi/erc20.json";
import * as customAlert from "js-alert";

function Club() {
	// let subtitle;
	const [modalIsOpen, setIsOpen] = React.useState(false);

	// var on modal
	let resultUSDC = 0;
	let resultMATIC = 0;

	let buttonUSDCState = 0;
	let buttonMATICState = 0;

	let amountInput = null;
	let usdcInput = null;
	let maticInput = null;
	let usdcButton = null;
	let maticButton = null;

	function bindInputs() {
		amountInput = document.getElementById("amount");
		usdcInput = document.getElementById("usdcAmount");
		maticInput = document.getElementById("maticAmount");
		usdcButton = document.getElementById("usdcButton");
		maticButton = document.getElementById("maticButton");
	}

	function setItDisableUSDC() {
		usdcButton.className = "disable";
		usdcButton.innerHTML = "<span></span><span></span><span></span><span></span>Insuficiente USDC";

		buttonUSDCState = 2;
	}

	function setItDisableMATIC() {
		maticButton.className = "disable";
		maticButton.innerHTML = "<span></span><span></span><span></span><span></span>Insuficiente MATIC";

		buttonMATICState = 2;
	}

	function setItEnableUSDC() {
		usdcButton.className = "a";
		usdcButton.innerHTML = "<span></span><span></span><span></span><span></span>Comprar con USDC";

		buttonUSDCState = 0;
	}


	function setItEnableMatic() {
		maticButton.className = "a";
		maticButton.innerHTML = "<span></span><span></span><span></span><span></span>Comprar con MATIC";

		buttonMATICState = 0;
	}

	function setItApproveUSDC() {
		usdcButton.className = "a";
		usdcButton.innerHTML = "<span></span><span></span><span></span><span></span>Aprobar gasto USDC";

		buttonUSDCState = 3;
	}

	function setItPendingUSDC() {
		usdcButton.className = "loading";
		usdcButton.innerHTML = "<span></span><span></span><span></span><span></span>Confirmando";

		buttonUSDCState = 1;
	}

	function setItPendingMatic() {
		maticButton.className = "loading";
		maticButton.innerHTML = "<span></span><span></span><span></span><span></span>Confirmando";

		buttonMATICState = 1;
	}

	async function openModal() {
		if (window.$web3) {
			window.alienSaleContract = new window.$web3.eth.Contract(
				alienSaleAbi,
				window.saleAddress
			);

			window.usdcContract = new window.$web3.eth.Contract(
				erc20Abi,
				window.usdcAddress
			);

			window.address = await window.$web3.eth.getAccounts();


			setIsOpen(true);

			bindInputs();
		} else {
			window.connectWeb3();
		}

	}

	async function onChangeAmount() {
		bindInputs();
		try {

			// Call section

			resultUSDC = await window.alienSaleContract.methods
				.getPriceUSDC(amountInput.value)
				.call({ from: window.address[0] });

			resultMATIC = await window.alienSaleContract.methods
				.getPriceMATIC(amountInput.value)
				.call({ from: window.address[0] });

			let balanceUSDC = await window.usdcContract.methods
				.balanceOf(window.address[0])
				.call({ from: window.address[0] });

			let balanceMatic = await window.$web3.eth.getBalance(window.address[0]);

			let allowanceUSDC = await window.usdcContract.methods.allowance(
				window.address[0],
				window.saleAddress
			).call({ from: window.address[0] });

			usdcInput.value = resultUSDC / 1000000;
			maticInput.value = (resultMATIC / 1000000000000000000).toFixed(5);

			setItEnableMatic();
			setItEnableUSDC();

			if (parseInt(allowanceUSDC) < parseInt(resultUSDC)) {
				setItApproveUSDC();
			}

			if (parseInt(balanceUSDC) < parseInt(resultUSDC)) {
				setItDisableUSDC();
			}

			if (parseInt(balanceMatic) < parseInt(resultMATIC)) {
				setItDisableMATIC();
			}


		} catch (e) {
			usdcInput.value = 0;
			maticInput.value = 0;

			setItEnableMatic();
			setItEnableUSDC();
		}
	}

	async function onClickUSDC() {
		if (buttonUSDCState === 2) {
			customAlert.alert("USDCs insuficientes para esta operación", "Error", window.errorImage);
			return;
		}

		try {

			if (buttonUSDCState === 3) {
				setItPendingUSDC();

				let result = await window.usdcContract.methods
					.approve(
						window.saleAddress,
						"115792089237316195423570985008687907853269984665640564039457584007913129639935"
					)
					.send({ gasPrice: "33000000000", from: window.address[0] });

				if (result.status) {
					buttonUSDCState = 0;
				}
			}

			if (buttonUSDCState === 0 && parseInt(resultUSDC) > 0) {
				if (await globalValidation()) {

					setItPendingUSDC();

					await window.alienSaleContract.methods
						.acceptBuyUSDC(amountInput.value)
						.send({
							from: window.address[0],
							gasPrice: "33000000000"
						});

					customAlert.alert("Compra de " + amountInput.value + " aliens reailzada correctamente.", "Compra con USDC");

					setItEnableUSDC();
				}
			}

		} catch (e) {

			console.log(e);

			if (e.message.indexOf("{") >= 0) {
				let err = JSON.parse(
					e.message.substring(e.message.indexOf("{")).trim()
				);
				if (err.message) {
					customAlert.alert(err.message, "Error Transacción", window.errorImage);
				} else {
					customAlert.alert("Error, Operación reversada, mirar el log para más información", "Error Transacción", window.errorImage);
				}
			} else {
				customAlert.alert(e.message, "Error Transacción", window.errorImage);
			}

			if (buttonUSDCState === 3) {
				setItApproveUSDC();
			} else {
				setItEnableUSDC();
			}

		}
	}

	async function onClickMATIC() {
		if (buttonMATICState === 2) {
			customAlert.alert("MATICs insuficientes para esta operación", "Error", window.errorImage);
			return;
		}

		try {

			if (buttonMATICState === 0 && parseInt(resultMATIC) > 0) {
				if (await globalValidation()) {
					setItPendingMatic();

					await window.alienSaleContract.methods
						.acceptBuyMATIC(amountInput.value)
						.send({
							from: window.address[0],
							gasPrice: "33000000000",
							value: resultMATIC
						});

					customAlert.alert("Compra de " + amountInput.value + " alien(s) reailzada correctamente.", "Compra con MATIC");

					setItEnableMatic();
				}
			}

		} catch (e) {
			console.log(e);
			if (e.message.indexOf("{") >= 0) {
				let err = JSON.parse(
					e.message.substring(e.message.indexOf("{")).trim()
				);
				if (err.message) {
					customAlert.alert(err.message, "Error Transacción", window.errorImage);
				} else {
					customAlert.alert("Error, Operación reversada, mirar el log para más información", "Error Transacción", window.errorImage);
				}
			} else {
				customAlert.alert(e.message, "Error Transacción", window.errorImage);
			}



			setItEnableMatic();
		}


	}

	async function globalValidation() {

		let limitNFT = await window.alienSaleContract.methods
			.limitNFT()
			.call({ from: window.address[0] });

		let nftBought = await window.alienSaleContract.methods
			.nftBought()
			.call({ from: window.address[0] });

		if (parseInt(limitNFT) < parseInt(nftBought) + parseInt(amountInput.value)) {
			customAlert.alert("No hay más NFTs para la venta", "Error", window.errorImage);
			return false;
		}

		let publicSale = await window.alienSaleContract.methods
			.publicSaleDate()
			.call({ from: window.address[0] });

		let currentTimeStamp = new Date().getTime();

		if (currentTimeStamp < publicSale * 1000) {

			let faseSaleDate = await window.alienSaleContract.methods
				.faseSaleDate()
				.call({ from: window.address[0] });

			let limitBuyFase = await window.alienSaleContract.methods
				.limitBuyFase()
				.call({ from: window.address[0] });

			let buyersCurrentFase = await window.alienSaleContract.methods
				.limitBuyFase()
				.call({ from: window.address[0] });

			if (currentTimeStamp < faseSaleDate) {
				customAlert.alert("La fase de preventa no está abierta", "Error", window.errorImage);
				return false;
			}

			if (limitBuyFase < buyersCurrentFase) {
				customAlert.alert("El límite para comprar NFT en esta preventa, se ha terminado", "Error", window.errorImage);
				return false;
			}
		}

		return true;
	}

	function afterOpenModal() {
		// references are now sync'd and can be accessed.
		// subtitle.style.color = '#f00';
	}

	function closeModal() {
		setIsOpen(false);
	}
	// all the cards image
	const cards = [
		{ image: "gellary/gellary (1).png" },
		{ image: "gellary/gellary (2).png" },
		{ image: "gellary/gellary (3).png" },
		{ image: "gellary/gellary (4).png" },
		{ image: "gellary/gellary (5).png" },
		{ image: "gellary/gellary (6).png" },
		{ image: "gellary/gellary (7).png" },
		{ image: "gellary/gellary (8).png" },
		{ image: "gellary/gellary (9).png" },
	];

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			background: 'transparent',
			border: 'none',
			fontFamily: 'sans-serif'
		}

	};

	// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
	Modal.setAppElement('#root');

	return (
		<div id="gellary" className="Club-section h-full ">
			<div className="">
				{/* <img className="w-full " src={sectionTwo} alt="hero" /> */}
				<div className=" w-full text-2xl text-[#FFF36A] font-['italic'] uppercase flex justify-center items-center md:text-3xl ">
					<h2 className="bg-[#F65FE9] px-5 py-1 shadow-lg rounded-3xl " onClick={openModal} >
						compra aqui
					</h2>
				</div>
				<div className=" flex justify-center flex-wrap pt-20">
					{cards.map((card) => (
						<div className="w-full md:w-1/2 lg:w-[26%] xl:w-[26%] py-3 flex items-center justify-center">
							<div className="w-full rounded-lg shadow-lg">
								<img className="xl:w-full" src={card.image} alt="card" />
							</div>
						</div>
					))}
				</div>
			</div>
			<Modal
				isOpen={modalIsOpen}
				onAfterOpen={afterOpenModal}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel="Example Modal"
			>
				<div class="login-box">
					<form>
						<div class="user-box">
							<input id="amount" type="number" name="" required onChange={onChangeAmount} min={0} />
							<label>¿Cuántos aliens comprarás? </label>
						</div>
						<br />
						<div class="user-box">
							<input id="usdcAmount" type="text" name="" readOnly value="0" />
							<label>Cantidad en USDC </label>
						</div>
						<div id="usdcButton" class="a" onClick={onClickUSDC}>
							<span /><span /><span /><span />
							Comprar con USDC
						</div>
						<br />
						<br />
						<div class="user-box">
							<input id="maticAmount" type="text" name="" readOnly value="0" />
							<label>Cantidad en MATIC </label>
						</div>
						<div id="maticButton" class="a" onClick={onClickMATIC}>
							<span /><span /><span /><span />
							Comprar con MATIC
						</div>
					</form>
				</div>
			</Modal>
		</div>
	);
}
export default Club;
